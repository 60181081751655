import Link from 'next/link'

import {Container} from '@/components/Container'
import {Logo} from '@/components/Logo'
import {NavLink} from '@/components/NavLink'
import {useState} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";

const links = [
    {label: 'Vrijblijvende demo', link: '/demo'},
    {label: 'Functies', link: '/#functies'},
    {label: 'Over ons', link: '/over-ons'},
    {label: 'Tarieven', link: '/#tarieven'},
    {label: 'Voordelen', link: '/#waarom-bijlesplein'},
    {label: 'Contact', link: '/contact'},
]

export function Footer() {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        axios.post('/api/subscribe', {
            email: data.email,
        })
            .then(function (response) {
                setError(null)
                setSuccess(true)
            })
            .catch(function (error) {
                if (error?.response?.data?.message) {
                    setError(error.response.data.message)
                } else {
                    setError('Er ging iets mis bij het versturen van het formulier. Probeer het later opnieuw.')
                }
            });
    };

    return (
        <footer className="bg-slate-50">
            <Container>
                <div className="pt-16 pb-10">
                    <Logo className="mx-auto h-10 w-auto"/>
                    <nav className="mt-10 text-sm" aria-label="quick links">
                        <div className="-my-1 flex justify-center gap-x-6 flex-wrap">
                            {links.map((link, index) => <NavLink key={index} href={link.link}>{link.label}</NavLink>)}
                        </div>
                    </nav>
                    <a href="https://www.linkedin.com/company/bijlesplein/" target="_blank" rel="noreferrer">
                        <svg className="mx-auto h-10 w-10 mt-8 text-slate-500 hover:text-slate-800" width="40"
                             height="40"
                             viewBox="0 0 40 40" fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M35 7.20588V32.7941C35 33.3792 34.7676 33.9402 34.3539 34.3539C33.9402 34.7676 33.3792 35 32.7941 35H7.20588C6.62085 35 6.05977 34.7676 5.64609 34.3539C5.2324 33.9402 5 33.3792 5 32.7941V7.20588C5 6.62085 5.2324 6.05977 5.64609 5.64609C6.05977 5.2324 6.62085 5 7.20588 5H32.7941C33.3792 5 33.9402 5.2324 34.3539 5.64609C34.7676 6.05977 35 6.62085 35 7.20588V7.20588ZM13.8235 16.4706H9.41176V30.5882H13.8235V16.4706ZM14.2206 11.6176C14.2229 11.2839 14.1595 10.953 14.0339 10.6438C13.9084 10.3346 13.7231 10.0532 13.4888 9.81558C13.2545 9.57797 12.9756 9.38884 12.6682 9.259C12.3608 9.12915 12.0308 9.06113 11.6971 9.05882H11.6176C10.939 9.05882 10.2882 9.32841 9.80829 9.80829C9.32841 10.2882 9.05882 10.939 9.05882 11.6176C9.05882 12.2963 9.32841 12.9471 9.80829 13.427C10.2882 13.9069 10.939 14.1765 11.6176 14.1765V14.1765C11.9514 14.1847 12.2835 14.1271 12.5949 14.0069C12.9064 13.8867 13.1911 13.7063 13.4328 13.4761C13.6746 13.2458 13.8685 12.9702 14.0037 12.6649C14.1389 12.3597 14.2126 12.0308 14.2206 11.6971V11.6176ZM30.5882 22.0118C30.5882 17.7676 27.8882 16.1176 25.2059 16.1176C24.3276 16.0737 23.4532 16.2607 22.6698 16.6602C21.8864 17.0596 21.2214 17.6575 20.7412 18.3941H20.6176V16.4706H16.4706V30.5882H20.8824V23.0794C20.8186 22.3104 21.0608 21.5472 21.5565 20.9558C22.0521 20.3643 22.7611 19.9923 23.5294 19.9206H23.6971C25.1 19.9206 26.1412 20.8029 26.1412 23.0265V30.5882H30.5529L30.5882 22.0118Z"></path>
                        </svg>
                    </a>
                </div>
                <div
                    className="border-t border-slate-400/10 py-8 lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h3 className="text-sm font-semibold leading-6 text-gray-900">Meld je aan voor onze
                            nieuwsbrief</h3>
                        <p className="mt-2 text-sm leading-6 text-gray-600">
                            Het laatste nieuws, artikelen en nieuwe functies direct in je inbox.
                        </p>
                    </div>
                    {success ?
                        <div className="rounded-md bg-green-50 p-4 mt-4 max-w-md">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor"
                                         className="h-5 w-5 text-green-400">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>

                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-green-800">Aangemeld</h3>
                                </div>
                            </div>
                        </div> :
                    <form onSubmit={handleSubmit(onSubmit)} className="mt-6 sm:flex sm:max-w-md lg:mt-0">
                        <label htmlFor="email-address" className="sr-only">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            required
                            className="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:w-56 sm:text-sm sm:leading-6"
                            placeholder="Vul je email in"
                            {...register("email", {required: true})}
                        />
                        <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                            <button
                                type="submit"
                                className="flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            >
                            Aanmelden
                            </button>
                        </div>
                    </form>
                    }
                </div>
                <div
                    className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
                    <div className="flex gap-x-6">
                        <p className="text-sm text-slate-500">KVK: 87979284</p>
                        <p className="text-sm text-slate-500">BTW: NL864465695B01</p>
                    </div>
                    <p className="mt-6 text-sm text-slate-500 sm:mt-0">
                        Copyright &copy; {new Date().getFullYear()} Bijlesplein. Alle rechten voorbehouden.{' '}
                        <Link href="/algemene-voorwaarden" className="underline">Algemene voorwaarden</Link>{' '}
                        <Link href="/privacy" className="underline">Privacy</Link>
                    </p>
                </div>
                <p className=" text-sm text-slate-500 text-center pb-10">
                    Ontwikkeld en gehost op eigen bodem 🇳🇱
                </p>
            </Container>
        </footer>
    )
}
